<template>
  <div class="terms-of-service">
    <h1>利用規約</h1>
    <p>
      この利用規約（以下、「本規約」といいます。）は、Clock Safety（以下、「本サービス」といいます。）の利用条件を定めるものです。本サービスをご利用いただく方（以下、「ユーザー」といいます。）には、本規約に従って本サービスをご利用いただきます。
    </p>

    <h2>第1条（適用）</h2>
    <p>
      本規約は、ユーザーと本サービスの間の一切の利用関係に適用されるものとします。本規約の他に、本サービスに関連する個別の利用ルールが存在する場合、これらも本規約の一部を構成します。
    </p>

    <h2>第2条（利用条件）</h2>
    <p>
      本サービスは、Googleスマートウォッチを利用し、Fitbitアカウントを登録しているユーザーのみが使用できます。本サービスの利用にあたっては、GoogleアカウントでのログインおよびFitbit認証が必要です。
    </p>

    <h2>第3条（ゲストログイン）</h2>
    <p>
      本サービスは、体験用のゲストログイン機能を提供します。ゲストログイン利用時には、機能が一部制限される場合があります。ゲストログインの利用履歴やデータは、一定期間後に削除されることがあります。
    </p>

    <h2>第4条（禁止事項）</h2>
    <ul>
      <li>法令または公序良俗に違反する行為。</li>
      <li>本サービスの不正利用やシステムへの不正アクセス。</li>
      <li>他のユーザーの個人情報を収集・蓄積する行為。</li>
      <li>サービスの運営を妨害する行為。</li>
      <li>その他、運営が不適切と判断する行為。</li>
    </ul>

    <h2>第5条（個人情報の取り扱い）</h2>
    <p>
      本サービスは、ユーザーの健康データを適切に保護し、第三者に提供することはありません。ただし、法令に基づく場合を除きます。Fitbitの認証を通じて取得したデータは、健康状態の確認目的以外には使用しません。
    </p>

    <h2>第6条（免責事項）</h2>
    <p>
      本サービスは、ユーザーの健康状態を保証するものではありません。本サービスの利用によって発生したいかなる損害についても、運営は責任を負いません。天災やシステムトラブル等によりサービスが停止した場合にも、運営は一切の責任を負わないものとします。
    </p>

    <h2>第7条（利用停止およびデータ削除）</h2>
    <p>
      ユーザーが本規約に違反した場合、運営は事前通知なしにサービス利用を停止することがあります。長期間サービスを利用しない場合、ユーザーのデータが削除されることがあります。
    </p>

    <h2>第8条（利用規約の変更）</h2>
    <p>
      本規約は、必要に応じて変更されることがあります。変更後の規約は、本サービス上に掲載した時点で効力を生じるものとします。ユーザーが変更後もサービスを利用する場合、改定内容に同意したものとみなされます。
    </p>

    <h2>第9条（お問い合わせ窓口）</h2>
    <p>
      本サービスに関するご質問やお問い合わせは、以下の連絡先までご連絡ください。
    </p>
    <p>メールアドレス: <a href="mailto:clock.safety.1@gmail.com">clock.safety.1@gmail.com</a></p>
    <p>この利用規約は、2024年11月26日から適用されます。</p>
  </div>
</template>

<script setup>

</script>

<style scoped>
.terms-of-service {
  margin: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  margin-top: 20px;
  color: #333;
}

ul {
  padding-left: 20px;
  list-style-type: disc;
}

p {
  margin-bottom: 15px;
}
</style>
