<template>
  <div class="main-content">
    <h1>プライバシーポリシー</h1>
    <p>
      Clock Safety（以下「当社」といいます）は、当社が提供するウェブアプリケーションにおいて、GoogleスマートウォッチおよびFitbitから取得した個人情報の取り扱いに関し、個人情報保護法、ガイドライン等の指針、その他個人情報保護に関する関係法令を遵守します。
    </p>

    <h2>1．取得する情報およびその取得方法、利用目的</h2>
    <p>
      当社が取得するユーザー情報は、以下の目的で使用されます。
    </p>
    <ul>
      <li>GoogleスマートウォッチおよびFitbitから取得した心拍数、睡眠データ、アクティビティデータの収集および管理</li>
      <li>遠方に住む親御さんの健康状態のモニタリングおよび異常値の通知</li>
      <li>健康データの分析および予防的な健康管理の支援</li>
      <li>サービス向上のためのデータ分析および機能改善</li>
      <li>法令遵守およびデータセキュリティの確保</li>
    </ul>
    <p>
      利用目的の変更が生じた場合には、ユーザーに通知し、同意を得た上で利用します。
    </p>

    <h2>2．個人情報の管理</h2>
    <p>
      当社は、取得した個人情報を以下の方法で管理します。
    </p>
    <ul>
      <li>常に正確かつ最新の情報となるよう努める</li>
      <li>個人情報の保存期間を設定し、保存期間終了後は適切に廃棄する</li>
      <li>安全管理措置に基づく厳重なデータ管理</li>
      <li>個人情報管理を委託する場合の適切な委託先の選定と監督</li>
    </ul>

    <h2>3．個人情報の第三者への提供</h2>
    <p>
      当社は、ユーザーの同意を得ない限り、取得した個人情報を第三者に提供することはありません。今後、第三者提供を行う場合は、提供する情報と目的を明示し、同意を得た上で行います。
    </p>

    <h2>4．個人情報の開示・訂正・利用停止</h2>
    <p>
      個人情報について、開示、訂正、利用停止等のお申し出があった場合には、本人確認を行った上で、当社所定の方法に基づき対応致します。詳細については、下記の受付窓口までお問い合わせください。
    </p>

    <h2>5．お問い合わせ先</h2>
    <p>
      本サービス、個人情報の取り扱いについては、以下の窓口にご連絡ください。
    </p>
    <p>
      メールアドレス: <a href="mailto:clock.safety.1@gmail.com">clock.safety.1@gmail.com</a>
    </p>

    <h2>6．制定日、改定日</h2>
    <p>制定日：2024年11月26日</p>
    <p>改定日：[改定日] - 改定内容：プライバシーポリシーの更新を行いました。</p>

  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicyPage'
}
</script>

<style scoped>
.main-content {
  margin: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  text-align: left;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  margin-top: 20px;
  color: #333;
}

ul {
  padding-left: 20px;
  list-style-type: disc;
}

p {
  margin-bottom: 15px;
}
</style>
